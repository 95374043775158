const $ = require("jquery");

var isSP = function() {
	return $('.u-sm-max:visible').length != 0;
};

import SmoothScroll from 'smooth-scroll';

//smoothscroll
var scroll = new SmoothScroll('a[href*="#"]',{
	header: '[data-scroll-header]',
	updateURL: false,
	speed: 400,
});

document.addEventListener('DOMContentLoaded', function() {
  var hash = window.location.hash;
  if (hash) {
    var target = document.querySelector(hash);
    if (target) {
      var header = document.querySelector('[data-scroll-header]');
      var headerHeight = header ? header.offsetHeight : 0;
      var scrollOffset = -headerHeight;

      setTimeout(function() {
        window.scrollTo(0, target.getBoundingClientRect().top + window.pageYOffset + scrollOffset);
      }, 0);
    }
  }
});

$(function() {
	// 固定header
  var $win = $(window),
      $header = $('.l-header');
  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > 100 ) {
      $header.addClass('_white_');
    } else {
      $header.removeClass('_white_');
    }
	});
	
	// SP header menu
	$('.l-headerMenu').click(function() {
		$(this).toggleClass('_active_');
		$('body').toggleClass('_gnav-show_');
	});
    $(".l-gnav__btn__close .__link").click(function (event) {
        event.preventDefault();
        $("body").removeClass("_gnav-show_");
        $(".l-headerMenu").removeClass("_active_");
    });
	if (isSP()) {
			$('.l-gnav__link[href]').on('click', function(event) {
			$('.l-headerMenu').trigger('click');
		});
	}
    $(".l-gnav__subitem").click(function (event) {
        $("body").removeClass("_gnav-show_");
        $(".l-headerMenu").removeClass("_active_");
    });

	// current
	$(document).ready(function() {
		if(location.pathname != "/") {
			$('.l-gnav__link[href^="/' + location.pathname.split("/")[1] + '"]').addClass('_current_');
		}
	});

	// pagetop
  var $pagetop = $('.c-pagetop');
	$(document).ready(function(){
		$pagetop.hide();
		$(window).on('scroll', function() {
				if ($(this).scrollTop() > 300) {
					$pagetop.fadeIn('slow');
				} else {
					$pagetop.fadeOut('slow');
				}
				const scrollHeight = $(document).height(),
							scrollPosition = $(window).height() + $(window).scrollTop(),
							footHeight = $('.l-footer').innerHeight();
				if (scrollHeight - scrollPosition <= footHeight) {
					$pagetop.css({
						'position': 'absolute',
						'bottom': footHeight + 20
					});
				} else {
					$pagetop.css({
						'position': 'fixed',
						'bottom': '55px'
					});
				}
		});
	});
});

$(function () {
  // Accordion
  $(".l-gnav__link._down_").click(function () {
    $(this).next().slideToggle();
    $(this).toggleClass("_drop_");
  });
});
